.sandbox-container {
    display: flex;
    height: 100vh;
  }
  
  .editor {
    flex: 1;
    height: 100vh;
    padding: 10px;
  }
  
  .renderer {
    flex: 1;
    background-color: #f0f0f0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  select {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  