.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: #000000; /* Black background */
}

h1 {
  font-size: 4rem;
  color: #3498db;
  margin-bottom: 10px;
}

p {
  font-size: 1.8rem;
  color: #cccccc;
  margin-bottom: 50px;
}

.github-logo {
  width: 50px;
  height: 50px;
}

footer {
  position: absolute;
  bottom: 40px;
}
